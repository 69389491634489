<template>
  <section class="trashbox block">
    <Preloader v-if="pending"/>
    <div class="trashbox-header block-wrapper header">
      <h1 class="trashbox-title title">Deleted articles</h1>
<!--      <Dropdown/>-->

      <button class="btn blue" @click="restoreSelected">Restore selected</button>
      <button class="btn red" @click="deletePermanently">Delete selected</button>
    </div>
    <ul v-if="list.length > 0" class="trashbox-list">
      <li class="trashbox-item" v-for="(item, idx) in list" :key="`trashbox-${idx}`">
        <Checkbox :value="checkedArticle.indexOf(item.id) >= 0" @input="check(item.id)"/>
        <p class="trashbox-item-name">{{ item.user_email }}</p>
        <div class="trashbox-item-title">
          <span class="lang">{{ item.version.language }}</span>
          <p>{{ item.version.title }}</p>
        </div>
        <p class="trashbox-item-date">
          {{ $moment(item.deleted_date).format('YYYY-MM-DD HH:MM:SS') }}
        </p>
      </li>
    </ul>
    <div v-else class="table-empty">
      <p v-if="!pending">Empty</p>
    </div>
    <APagination ref="pagination" :visible="list.length > 0" :page="page" :count="paginationPages"
                 :callback="setPagination"/>
  </section>
</template>

<script>
import {eventBus} from '@/main';
import {mapActions} from 'vuex';
import Preloader from '@/components/common/Preloader';

export default {
  name: "TrashboxPage",
  components: {
    // Dropdown: () => import('@/components/common/ADropdown'),
    Checkbox: () => import('@/components/common/ACheckbox'),
    APagination: () => import('@/components/common/APagination'),
    Preloader,
  },
  data() {
    return {
      list: [],
      checkedArticle: [],
      page: 1,
      quantity: 30,
      total: 0,
      pending: false
    }
  },
  computed: {
    checkAllBool() {
      return this.list.length === this.checkedArticle.length
    },
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if ((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    }
  },
  methods: {
    ...mapActions(['getDeletedArticles', 'deleteArticlePermanently', 'restoreArticles']),
    check(id) {
      const idx = this.checkedArticle.indexOf(id);
      if (idx >= 0) {
        this.checkedArticle.splice(idx, 1);
      } else {
        this.checkedArticle.push(id);
      }
    },
    checkAll() {
      this.checkedArticle = this.checkedArticle.length === 0 ? this.list.map(x => x.id) : [];
    },
    async setPagination(num) {
      this.page = num;
      await this.loadArticles();
    },
    async restoreSelected() {
      if (this.checkedArticle.length > 0) {
        this.pending = true;

        await this.restoreArticles(this.checkedArticle)
          .then(async () => {
            this.$notify({
              title: 'Restore articles',
              text: 'Articles restored successfully!',
              type: 'success'
            });
            this.checkedArticle = [];
            await this.loadArticles();
          }).catch(error => {
            this.pending = false;
            this.$notify({
              title: 'Restore articles',
              text: error.response.data.message,
              type: 'error'
            });
          });
      }
    },
    async deletePermanently() {
      if (this.checkedArticle.length > 0) {
        this.pending = true;

        await this.deleteArticlePermanently(this.checkedArticle)
          .then(async () => {
            this.checkedArticle = [];
            await this.loadArticles();
          }).catch(error => {
            this.pending = false;
            this.$notify({
              title: 'Delete articles',
              text: error.response.data.message,
              type: 'error'
            });
          });
      }
    },
    async loadArticles() {
      this.pending = true;

      await this.getDeletedArticles({per_page: this.quantity, current_page: this.page})
        .then(({data, pagination}) => {
          this.pending = false;
          this.total = pagination.total;
          this.list = data;

          if (data.length === 0 && this.page > 1) {
            this.$refs['pagination'].onChangePage(1);
          }
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Deleted articles',
            text: error.response.data.message,
            type: 'error'
          });
        });
    }
  },
  async created() {
    eventBus.$on('onLoadArticles', async data => {
      await this.loadArticles();
    });
  }
}
</script>

<style scoped>

</style>
